import htmlLogo from "../../assets/images/html-logo.jpg";
import cssLogo from "../../assets/images/css-logo.jpg";
import javaScriptLogo from "../../assets/images/javaScript-logo.jpg";
import reactLogo from "../../assets/images/react-logo.jpg";
import typeScriptLogo from "../../assets/images/typescript-logo.jpg";
import sassLogo from "../../assets/images/sass-logo.jpg";
import angularLogo from "../../assets/images/angular-logo.jpg";
import cSharpLogo from "../../assets/images/csharp.svg";
import dotNetLogo from "../../assets/images/dotNetLogo.png";
import netCoreLogo from "../../assets/images/NET_Core_Logo.png";
import nodeJSLogo from "../../assets/images/node-js-logo.png";
import tSqlLogo from "../../assets/images/t-sql-logo.png";
import sqlServerLogo from "../../assets/images/sql-server-logo.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./skills.css";

function Skills() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skills" id="skills">
      <div className="skills-container">
        <div className="skills-section-color">
          <div className="skills-title-container">
            <h2>Skills</h2>
          </div>
        </div>
        <div className="skills-tier-container">
          <h4>Front-End</h4>
          <Carousel
            responsive={responsive}
            infinite={true}
            className="skill-carousel"
          >
            <div className="skill-container">
              <img src={htmlLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>HTML</h5>
            </div>
            <div className="skill-container">
              <img src={cssLogo} alt="CSS3 Logo" className="skill-logo" />
              <h5>CSS</h5>
            </div>
            <div className="skill-container">
              <img
                src={javaScriptLogo}
                alt="JavaScript Logo"
                className="skill-logo"
              />
              <h5>JavaScript</h5>
            </div>
            <div className="skill-container">
              <img src={reactLogo} alt="React Logo" className="skill-logo" />
              <h5>React</h5>
            </div>
            <div className="skill-container">
              <img
                src={typeScriptLogo}
                alt="TypeScript Logo"
                className="skill-logo"
              />
              <h5>TypeScript</h5>
            </div>
            <div className="skill-container">
              <img src={sassLogo} alt="Sass Logo" className="skill-logo" />
              <h5>Sass/Scss</h5>
            </div>
            <div className="skill-container">
              <img
                src={angularLogo}
                alt="Angular Logo"
                className="skill-logo"
              />
              <h5>Angular</h5>
            </div>
          </Carousel>
        </div>
        <div className="skills-tier-container">
          <h4>Middle-Tier</h4>
          <Carousel
            responsive={responsive}
            infinite={true}
            className="skill-carousel"
          >
            <div className="skill-container">
              <img src={cSharpLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>C#</h5>
            </div>
            <div className="skill-container">
              <img src={dotNetLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>.Net</h5>
            </div>
            <div className="skill-container">
              <img src={netCoreLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>.Net Core</h5>
            </div>
          </Carousel>
        </div>
        <div className="skills-tier-container">
          <h4>Back-End</h4>
          <Carousel
            responsive={responsive}
            infinite={true}
            className="skill-carousel"
          >
            <div className="skill-container">
              <img src={nodeJSLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>C#</h5>
            </div>
            <div className="skill-container">
              <img src={tSqlLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>T-SQL</h5>
            </div>
            <div className="skill-container">
              <img src={sqlServerLogo} alt="HTML5 Logo" className="skill-logo" />
              <h5>SQL Server Management Studio</h5>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Skills;
