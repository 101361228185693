import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MailchimpForm from "../mail/MailChimpForm";
import logo from "../../assets/images/logo.svg";
import navIcon1 from "../../assets/images/nav-icon1.svg";
import navIcon2 from "../../assets/images/nav-icon2.svg";
import navIcon3 from "../../assets/images/nav-icon3.svg";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col xs={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <button>
                <img src={navIcon1} alt="Icon" />
              </button>
              <button>
                <img src={navIcon2} alt="Icon" />
              </button>
              <button>
                <img src={navIcon3} alt="Icon" />
              </button>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
